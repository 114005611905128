import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Moment from "react-moment"
import ReactPlayer from "react-player/vimeo"
import Icon from "@mdi/react"
import { mdiPlayCircleOutline, mdiChevronLeft, mdiChevronRight } from "@mdi/js"
import scrollTo from "gatsby-plugin-smoothscroll"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Monetizer from "../components/monetizer"

import "../styles/video-album.css"

const VideoAlbum = ({ data, pageContext }) => {
  const [activeVid, setActiveVid] = useState(
    data.strapiAlbum.tracks[0].video_link
  )
  const [autoPlay, setAutoPlay] = useState(false)

  const album = data.strapiAlbum
  const albumSlug = pageContext.albumSlug
  const notAlphaNum = /\W/g
  const space = /\s/g
  const slugArray = data.allStrapiAlbum.edges.map(({ node }) =>
    node.title.replace(space, "_").replace(notAlphaNum, "")
  )
  const currentIndex = slugArray.indexOf(albumSlug)
  const modifiedSlugArrayLength = slugArray.length - 1
  const prevPage = currentIndex - 1
  const nextPage = currentIndex + 1
  const notFirstEntry = currentIndex !== 0
  const notLastEntry = currentIndex !== modifiedSlugArrayLength

  const ogImage = album.open_graph_image
  const isOgImage =
    ogImage !== null && ogImage !== "" && ogImage !== undefined
      ? ogImage.localFile.publicURL
      : false

  const handleClick = (e, url) => {
    e.preventDefault()
    setActiveVid(url)
    setAutoPlay(true)
    scrollTo("#video")
  }

  return (
    <Layout>
      <SEO
        title={album.title}
        description={album.album_description}
        pageImage={isOgImage}
        pathname={albumSlug}
      />
      <div className="video-album">
        <div className="video-album-container">
          <div className="video-album-title-line">
            <Link
              className="video-album-nav"
              to={
                notFirstEntry
                  ? `/video/${slugArray[prevPage]}`
                  : `/video/${slugArray[modifiedSlugArrayLength]}`
              }
            >
              <div className="video-album-icon">
                <Icon path={mdiChevronLeft} size={2} />
              </div>
            </Link>
            <div className="video-album-title">
              <h1>{album.title}</h1>
              <h3>
                &#x000A0;&#x00028;
                <Moment local format="YYYY">
                  {album.release_date}
                </Moment>
                &#x00029;
              </h3>
            </div>
            <Link
              className="video-album-nav"
              to={
                notLastEntry
                  ? `/video/${slugArray[nextPage]}`
                  : `/video/${slugArray[0]}`
              }
            >
              <div className="video-album-icon">
                <Icon path={mdiChevronRight} size={2} />
              </div>
            </Link>
          </div>
          <div className="video-album-body">
            <div className="video-album-content">
              <div className="video-album-player-wrapper">
                <div className="video-album-player" id="video">
                  <ReactPlayer
                    className="react-player"
                    url={`https://vimeo.com/${activeVid}`}
                    width="100%"
                    height="100%"
                    config={{
                      vimeo: {
                        playerOptions: {
                          maxwidth: 1280,
                          autoplay: autoPlay,
                          playsinline: true,
                          controls: true,
                        },
                      },
                    }}
                  />
                </div>
              </div>
              <div className="video-album-list">
                <p className="video-album-description">
                  {data.strapiAlbum.album_description}
                </p>
                <ul className="video-album-scroller snaps-inline">
                  {data.strapiAlbum.tracks.map((infos, index) => {
                    return (
                      <li
                        className="video-album-element"
                        key={index}
                        onClick={e => handleClick(e, infos.video_link)}
                      >
                        <h4 className="video-album-list-item-title">
                          <div>{infos.track_number}</div>
                          <div>{infos.track_title}</div>
                        </h4>
                        <div className="video-album-list-item-image">
                          <GatsbyImage
                            image={getImage(infos.video_poster?.localFile)}
                            alt=""
                            objectFit="contain"
                          />
                          <Icon
                            className="video-album-list-item-icon"
                            path={mdiPlayCircleOutline}
                            size="3rem"
                          />
                        </div>
                      </li>
                    )
                  })}
                </ul>
                <Monetizer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($albumTitle: String!) {
    strapiAlbum(title: { eq: $albumTitle }) {
      title
      album_description
      release_date
      front_cover {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(quality: 100)
          }
        }
      }
      open_graph_image {
        localFile {
          publicURL
        }
      }
      tracks {
        track_number
        track_title
        video_link
        video_poster {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: FIXED
                quality: 100
                aspectRatio: 1.66667
                width: 300
              )
            }
          }
        }
      }
    }
    allStrapiAlbum(
      filter: { album_type: { eq: "video" } }
      sort: { fields: release_date, order: DESC }
    ) {
      edges {
        node {
          title
        }
      }
    }
  }
`

export default VideoAlbum
